import React from "react";
import styles from "./Legal.module.scss";
import { Link } from "react-router-dom";

interface LegalProps {
  accountId?: string;
}

const Legal: React.FC<LegalProps> = (props) => {
  const { accountId } = props;

  function feedbackURL() {
    return `/new/feedback/${accountId}`;
  }
  function faqURL() {
    return `https://engie.com.au/residential/energy-efficiency/reduce-and-reward#faqs`;
  }

  return (
    <div id="footer" className={styles.footer}>
      <div className={styles.content}>
        <div>
          <p>
            For full program Terms & Conditions, visit
            www.engie.com.au/residential/energy-efficiency/reduce-and-reward. *
            To be eligible for the Gold Super Saver bonus credit, you must be a
            current ENGIE electricity customer who opted-in to the event, and be
            in the top 2% of savers below their baseline.
          </p>
          <br />
          <p>Need to get in touch? Call us on 13 88 08.</p>
          <br />
          <p>
            ENGIE (ABN 67 269 241 237) is a partnership comprising IPower Pty
            Ltd (ACN 111 267 228) and IPower 2 Pty Ltd (ACN 070 374 293).
          </p>
        </div>
        <div className={styles.footerLinks}>
          <a href={faqURL()}>FAQ</a>
          {accountId && (
            <>
              <span> | </span>
              <Link
                to={{
                  pathname: feedbackURL(),
                }}
              >
                Feedback
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Legal;
