import styles from "./RewardsStructure.module.scss";

export default function RewardsStructure() {
  return (
    <div className={styles.rewardsStructure}>
      <div className={styles.content}>
        <div className={styles.itemFullLine}>
          <h2 className={`${styles.textCenter} ${styles.heading}`}>
            How much can I receive during an event?
          </h2>
          <div className={styles.rewardItems}>
            <div
              data-testid="gold-super-saver-description"
              className={`${styles.rewardItem} ${styles.gold}`}
            >
              <div className={styles.rewardIcon}>
                <img
                  src="/static/icons/piggy-bank.svg"
                  alt="Gold Super Saver"
                />
              </div>
              <div>
                <h5>Top 2% of kWh reducers</h5>
                <h4>Gold Super Saver</h4>
                <h6>$100 bill credit</h6>
              </div>
            </div>
            <div
              data-testid="gold-super-saver-bill"
              className={styles.rewardItem}
            >
              <div className={styles.rewardIcon}>
                <img
                  src="/static/icons/tarrif-shield.svg"
                  alt="Silver Saver"
                />
              </div>
              <div>
                <h5>30% or more below your baseline</h5>
                <h4>Silver Saver</h4>
                <h6>$5 bill credit</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
