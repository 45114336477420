import React from "react";
import styles from "./EventHistory.module.scss";
import { Link } from "react-router-dom";
import { formatDate, dollars } from "../../services/Filters";
import { ReduceAndRewardEvent } from "trust-insights-types";
import { RewardAmount } from "../../enums/RewardAmount";

interface EventHistoryProps {
  accountId: string;
  eventHistory: ReduceAndRewardEvent[] | null;
}
const EventHistory: React.FC<EventHistoryProps> = (props) => {
  const { accountId, eventHistory } = props;

  return (
    <div className={styles.eventHistory}>
      <div className={styles.content}>
        <div className={styles.itemFullLine}>
          <h2>Event History</h2>
        </div>
        <div className={styles.history}>
          <p>Click on the date for any event to see your interval data targets and performance.</p>
          {eventHistory && (
            <table>
              <thead>
                <tr>
                  <th scope="col" style={{width: "25%"}}>DATE</th>
                  <th scope="col" style={{width: "25%"}}>RESULT</th>
                  <th scope="col" style={{width: "25%"}}>BILL CREDIT</th>
                  <th scope="col" style={{width: "25%"}}>RANKING</th>
                </tr>
              </thead>
              <tbody>
                {eventHistory.map((event) => (
                  <tr key={event.id}>
                    {event.event_usage_exists ? (
                      <td>
                        <Link
                          className={styles.historyLink}
                          to={{
                            pathname:
                              `/ReduceAndReward/${accountId}` +
                              "/event/" +
                              `${event.dr_event.id}`,
                          }}
                        >
                          {formatDate(
                            event.dr_event.start_time,
                            "DD MMMM YYYY"
                          )}
                        </Link>
                      </td>
                    ) : (
                      <td>
                        {formatDate(event.dr_event.start_time, "DD MMMM YYYY")}
                      </td>
                    )}

                    {!event.event_usage_exists ? (
                      <td>
                        <span className={styles.missingUsageResult}>
                          Missing
                        </span>
                      </td>
                    ) : event.passed ? (
                      <td>
                        <span className={styles.successResult}>Success!</span>
                      </td>
                    ) : (
                      <td>
                        <span className={styles.failResult}>Almost!</span>
                      </td>
                    )}

                    {event.dr_event_rewards && (
                      <td>{dollars(event.dr_event_rewards.cents / 100, 0)}</td>
                    )}

                    {event.event_usage_exists && (
                      <td>
                        {event.dr_event_rewards.cents === RewardAmount.Bronze ? (
                          <span className={styles.ranking}><img
                          src="/static/icons/bronze-rank.svg"
                          alt="bronze"
                        /></span>
                        ) : event.dr_event_rewards.cents === RewardAmount.Silver ? (
                          <span className={styles.ranking}><img
                          src="/static/icons/silver-rank.svg"
                          alt="silver"
                        /></span>
                        ) : event.dr_event_rewards.cents >= RewardAmount.Gold ? (
                          <span className={styles.ranking}>
                            <img
                          src="/static/icons/gold-rank.svg"
                          alt="gold"
                        />
                          </span>
                        ) : null}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventHistory;
