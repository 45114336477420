/* eslint-disable no-unused-vars */
enum RewardRank {
  Gold,
  Silver,
  Bronze,
  Missing,
}
/* eslint-enable no-unused-vars */

export { RewardRank };
