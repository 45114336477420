import React from "react";
import styles from "./RewardsOverview.module.scss";
import { dollars } from "../../services/Filters";
import { ReduceAndRewardRewards } from "trust-insights-types";
import { RewardRank } from "../../enums/RewardRank";
import { RewardAmount } from "../../enums/RewardAmount";

interface RewardsOverviewProps {
  rewards: ReduceAndRewardRewards | null;
  totalRewards: ReduceAndRewardRewards | null;
  showRank: boolean;
}

function getRank(rewards: ReduceAndRewardRewards | null) {
  if (rewards && !isNaN(rewards.cents)) {
    if (rewards.cents === RewardAmount.Silver) {
      return RewardRank.Silver;
    } else if (rewards.cents >= RewardAmount.Gold) {
      return RewardRank.Gold;
    } else {
      return RewardRank.Bronze;
    }
  } else {
    return RewardRank.Missing;
  }
}

const RewardsOverview: React.FC<RewardsOverviewProps> = (props) => {
  let { rewards, totalRewards, showRank } = props;

  return (
    <div className={styles.rewardsOverview}>
      <div className={styles.content}>
        <div className={styles.itemFullLine}>
          <h2>Event Portal</h2>
          <p>
            Welcome to your Reduce &amp; Reward event portal. Check out how you
            performed in past events.
          </p>
        </div>
        {/* Bill credit */}
        {showRank ? (
          <div
            className={`${styles.items} ${styles.rank} ${styles.itemFullLine} ${
              getRank(rewards!) === RewardRank.Gold && styles.gold
            } ${getRank(rewards!) === RewardRank.Silver && styles.silver} ${
              getRank(rewards!) === RewardRank.Bronze && styles.bronze
            } ${getRank(rewards!) === RewardRank.Missing && styles.missing}`}
          >
            <div className={styles.itemsCenter}>
              <div className={styles.itemLeft}>
                {rewards && rewards.cents ? (
                  <div>
                    <h5 className={`${styles.title} ${styles.textBlue}`}>
                      {dollars(rewards.cents / 100, 0)}
                    </h5>
                    <span className={styles.text}>
                      Bill credit
                    </span>
                  </div>
                ) : rewards ? (
                  <div>
                    <h5 className={`${styles.title} ${styles.textBlue}`}>$0</h5>
                    <span className={styles.text}>
                      Bill credit
                    </span>
                  </div>
                ) : (
                  <div>{/* Show nothing */}</div>
                )}
              </div>
            </div>
            <div className={styles.itemsCenter}>
              <div className={styles.itemLeft}>
                {rewards && rewards.cents ? (
                  <div>
                    {rewards.cents === RewardAmount.Silver ? (
                      <div>
                        <h5 className={`${styles.title} ${styles.textBlue}`}>
                          Silver Saver
                        </h5>
                        <span className={styles.text}>
                          More than 30% below your baseline
                        </span>
                      </div>
                    ) : rewards.cents >= RewardAmount.Gold ? (
                      <div>
                        <h5 className={`${styles.title} ${styles.textBlue}`}>
                          Gold Super Saver
                        </h5>
                        <span className={styles.text}>
                        Top 2% Savers
                        </span>
                      </div>
                    ) : null}
                  </div>
                ) : rewards ? (
                  <div>
                    <h5 className={`${styles.title} ${styles.textBlue}`}>
                      Bronze
                    </h5>
                    <span className={styles.text}>Almost!</span>
                  </div>
                ) : (
                  <div>{/* Show nothing */}</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`${styles.itemFullLine} ${styles.itemsCenter} ${styles.totalBillCreditsEarned}`}
          >
            {totalRewards && (
              <div className={styles.currencySymbol}>
                <img
                  src="/static/icons/debit-card.svg"
                  alt="overview currency symbol"
                />
              </div>
            )}
            <div className={styles.itemLeft}>
              {totalRewards && totalRewards.cents ? (
                <div>
                  <h5 className={`${styles.title} ${styles.textBlue}`}>
                    {dollars(totalRewards.cents / 100, 0)}
                  </h5>
                  <span className={styles.text}>Total bill credits earned</span>
                </div>
              ) : totalRewards ? (
                <div>
                  <h5 className={`${styles.title} ${styles.textBlue}`}>$0</h5>
                  <span className={styles.text}>Total bill credits earned</span>
                </div>
              ) : (
                <div>{/* Show nothing */}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default RewardsOverview;
